<template>
    <div class="container">
        <div class="success">
            <img src="../assets/wx.png" alt="" srcset="" />
        </div>
        <div class="tips" v-if="this.$route.query.type == 1">订单支付成功</div>
        <div class="tips" v-else>领取成功</div>
        <div class="download" v-if="this.$route.query.type == 1">
            购买完成，您需要下载app学习
        </div>
        <div class="download" v-else>领取完成完成，您需要下载app学习</div>
        <wx-open-launch-weapp v-if="showPushRet" id="launch-btn" appid="wxd20f2b63ea9ce6b7" username="gh_2e2fea57f269"
            :path="tinyPath">
            <script type="text/wxtag-template">
                <style>.btn {display: inline-block;
        width: 140px;
        height: 44px;
        line-height: 44px;
        background: #ef0923;
        border-radius: 22px;
        margin: 24px auto 30px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-align: center;}</style>
                                <div class="btn">打开小程序</div>
                                    </script>
        </wx-open-launch-weapp>
    </div>
</template>
  
<script>
import Vconsole from 'vconsole'

let vConsole = new Vconsole()
import {
    requestQueryDataTGPMethod,
} from "../utils/appManager";
import { wxconfig } from "./wxApi.js"
import Vue from 'vue';
export default {
    components: {},
    props: {},
    data() {
        return {
            isWXRet: false,
            jssdkTicket: '',
            jssdkTicket2: '',
            showPushRet: false,
            tinyPath: "pages/onlyStocks/onlyStocks?stockCode="
        };
    },
    watch: {},
    computed: {},
    methods: {
        loadWX() {
            document.addEventListener("WeixinOpenTagsError", function (t) {
                console.log("cccc");
                alert(t.detail.errMsg);
            });
            this.getjssdkTicket();
            var btn = document.getElementById('launch-btn');
            btn.addEventListener('launch', function (e) {
                console.log('success');
                console.log(e);
                console.log(e.detail);
            });
            btn.addEventListener('error', function (e) {
                console.log('fail', e.detail);
            });
        },
        getjssdkTicket() {
            requestQueryDataTGPMethod(
                "v4/wechat/getJssdk",
                { jsApiList: ['launchApplication', 'getInstallState'], url: window.location.href, openTagList: ['wx-open-launch-weapp'] },
                "getjssdkTicketSuccess",
                "0",
                "post"
            );
        },
    },
    created() {
        if (this.$route.query.type == 0) {
            document.title = "领取完成";
        } else {
            document.title = "购买完成";
        }
        window["getjssdkTicketSuccess"] = (data) => {
            console.log(data);
            this.jssdkTicket = data.data.signature;
            wxconfig(this.jssdkTicket, data.data.timestamp, data.data.url, data.data.nonceStr, data.data.appId);
            setTimeout(() => {
                this.showPushRet = true;
            }, 1000);
        }
    },
    mounted() {
        this.tinyPath = "pages/NewTabController/NewTabController?tabbarIndex=1";
        this.isWXRet = Boolean(navigator.userAgent.match(/MicroMessenger/ig));
        if (this.isWXRet) {
            this.loadWX();
        }
    },
};
</script>
<style lang="scss" scoped>
.container {
    .success {
        width: 80px;
        height: 80px;
        background: #07c160;
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 84px auto 24px;

        img {
            display: block;
            width: 50px;
            height: 50px;
        }
    }

    .tips {
        text-align: center;
        width: 100%;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }

    .download {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        margin-top: 40px;
        text-align: center;
        width: 100%;
    }
}

#launch-btn {
    margin-left: calc(50% - 70px);
    margin-top: 40px;
}

.btn {
    display: inline-block;
    width: 140px;
    height: 44px;
    line-height: 44px;
    background: #ef0923;
    border-radius: 22px;
    margin: 24px auto 30px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
}
</style>
  